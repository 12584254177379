@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .dropdown-display-block {
    display: block;
  }

  .dropdown-display-block::after {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 5px;
    width: 0;
    height: 0;
    content: '';
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid black;
  }

  .linear-indeterminate {
    overflow: hidden;
    background: linear-gradient(to right, #7d12ff, #7a6dd7, #948adf, #b4ade8, #d2cdf1);
  }

  .progress-bar {
    width: calc(100% * 2 / 7);
    animation: move 2s linear infinite;
  }

  @keyframes move {
    0% {
      transform: translate(-100%, 0);
    }
    100% {
      transform: translate(calc(7 / 2 * 100%), 0);
    }
  }

  .switch {
    width: 60px;
    height: 32px;
  }

  .slider {
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: '';
    height: 24px;
    width: 24px;
    left: 4px;
    bottom: 4px;
    background-color: #7d12ff;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: #cfc8ff;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  .slider.round {
    border-radius: 24px;
  }

  .slider.round:before {
    border-radius: 50%;
  }

  .h-fit-content {
    height: fit-content;
  }

  .h-content-24 {
    height: calc(100% - theme('spacing.24'));
  }

  .h-content-32 {
    height: calc(100% - theme('spacing.32'));
  }

  .menu-item i {
    background-size: cover !important;
    background-repeat: no-repeat !important;
  }
  .menu-item.active {
    color: #ffffff;
    background: #7d12ff;
  }
  .menu-item.dashboard i {
    background: url(./assets/dashboard-icon.png);
  }
  .menu-item.dashboard.active i {
    background: url(./assets/dashboard-white-icon.png);
  }
  .menu-item.engagements i {
    background: url(./assets/engagements-menu-icon.svg);
  }
  .menu-item.engagements.active i {
    background: url(./assets/engagements-menu-white-icon.svg);
  }
  .menu-item.screens i {
    background: url(./assets/screens-icon.png);
  }
  .menu-item.screens.active i {
    background: url(./assets/screens-white-icon.png);
  }
  .menu-item.content i {
    background: url(./assets/content-icon.png);
  }
  .menu-item.content.active i {
    background: url(./assets/content-white-icon.png);
  }
  .menu-item.subscribers i {
    background: url(./assets/subscribers-icon.png);
  }
  .menu-item.subscribers.active i {
    background: url(./assets/subscribers-white-icon.png);
  }
  .menu-item.playlists i {
    background: url(./assets/playlist-icon.png);
  }
  .menu-item.playlists.active i {
    background: url(./assets/playlist-white-icon.png);
  }
  .menu-item.design i {
    background: url(./assets/design-icon.png);
  }
  .menu-item.design.active i {
    background: url(./assets/design-white-icon.png);
  }
  .menu-item.monetize i {
    background: url(./assets/monetize-icon.png);
  }
  .menu-item.monetize.active i {
    background: url(./assets/monetize-white-icon.png);
  }
  .menu-item.phone i {
    background: url(./assets/phone-icon.png);
    width: 14px;
    margin-left: 27px;
  }
  .menu-item.phone.active i {
    background: url(./assets/phone-white-icon.png);
    width: 14px;
    margin-left: 27px;
  }
  .menu-item.rocket i {
    background: url(./assets/rocket.png);
  }
  .menu-item.rocket.active i {
    background: url(./assets/rocket-active.png);
  }
  .menu-item.account i {
    background: url(./assets/account-icon.png);
  }
  .menu-item.account.active i {
    background: url(./assets/account-white-icon.png);
  }
  .menu-item.settings i {
    background: url(./assets/settings-icon.png);
  }
  .menu-item.settings.active i {
    background: url(./assets/settings-white-icon.png);
  }
  .menu-item.networking i {
    background: url(./assets/community-icon.svg);
  }
  .menu-item.networking.active i {
    background: url(./assets/community-white-icon.png);
  }
  .menu-item.quest i {
    background: url(./assets/quest-icon.svg);
  }
  .menu-item.quest.active i {
    background: url(./assets/quest-white-icon.svg);
  }
  .menu-item.messaging i {
    background: url(./assets/messaging-icon.png);
  }
  .menu-item.messaging.active i {
    background: url(./assets/messaging-white-icon.png);
  }
  .menu-item.token i {
    background: url(./assets/token-icon.png);
  }
  .menu-item.token.active i {
    background: url(./assets/token-white-icon.png);
  }
  .menu-item.interests i {
    background: url(./assets/interests-icon.png);
  }
  .menu-item.interests.active i {
    background: url(./assets/interests-white-icon.png);
  }
  .menu-item.outreach i {
    background: url(./assets/outreach-icon.png);
  }
  .menu-item.outreach.active i {
    background: url(./assets/outreach-white-icon.png);
  }
  .menu-item.meetings i {
    background: url(./assets/meetings-icon.svg);
  }
  .menu-item.meetings.active i {
    background: url(./assets/meetings-white-icon.svg);
  }
}

.submenu-item.active i {
  background: url(./assets/small-right-manatee.png);
  background-size: cover !important;
  background-repeat: no-repeat !important;
}
.submenu-item.active span {
  @apply font-bold;
}

.form-row-select__control {
  @apply rounded-lg mt-2 bg-white-smoke shadow-none border-transparent border-2 border-solid !important;
}
.form-row-select__control--is-focused {
  @apply bg-white border-slate-blue !important;
}
.form-row-select__value-container {
  @apply rounded-lg px-4 py-2 !important;
}
.form-row-select__single-value {
  @apply text-sm text-black-russian !important;
}
.form-row-select__menu {
  @apply text-black-russian rounded-3xl border-whisper border-2 py-2 shadow-none z-20 !important;
}
.form-row-select__placeholder {
  @apply text-manatee text-sm !important;
}
.form-row-select__option,
.form-row-select__option--is-focused {
  @apply bg-transparent text-manatee text-sm px-6 !important;
}

.form-row-select__option--is-focused {
  @apply text-black-russian !important;
}

.form-row-select {
  @apply mt-2 !important;
}
.dropdown-container {
  @apply h-11 rounded-lg py-0.5 bg-white-smoke border-none text-sm text-black-russian !important;
}
.dropdown-container .dropdown-content {
  @apply text-sm text-black-russian font-sans !important;
}
.dropdown-container .dropdown-content .panel-content {
  @apply rounded-3xl border-2 border-whisper shadow-none py-2 px-2 !important;
}
.dropdown-container .dropdown-content .panel-content:focus,
.dropdown-container .dropdown-content .panel-content:hover,
.dropdown-container .dropdown-content .select-item,
.dropdown-container .dropdown-content .select-item.selected {
  @apply bg-white !important;
}
.dropdown-container .dropdown-content .select-item .item-renderer {
  @apply items-center text-black-russian text-sm !important;
}
.dropdown-container .dropdown-heading {
  @apply mt-1;
}

input[type='radio'] + label.radio-label span {
  transition:
    background 0.2s,
    transform 0.2s;
}

input[type='radio']:not(:disabled) + label.radio-label:hover span {
  transform: scale(1.1);
}

input[type='radio']:checked + label.radio-label span {
  background-color: white;
  box-shadow: 0px 0px 0px 4px #7d12ff inset;
}

input[type='radio']:checked:disabled + label.radio-label span {
  background-color: white;
  box-shadow: 0px 0px 0px 4px #9b98ac inset;
}

input[type='radio']:disabled + label.radio-label {
  cursor: not-allowed;
}

input[type='checkbox']:checked + label.checkbox-label,
input[type='radio']:checked + label.radio-label {
  color: #000;
}

input[type='checkbox'] + label.checkbox-label div {
  transition:
    background 0.2s,
    transform 0.2s;
}

input[type='checkbox']:checked + label.checkbox-label div {
  @apply bg-slate-blue;
  @apply border-0;
}

input[type='checkbox']:checked + label.checkbox-label div svg {
  @apply block;
}

.progressbar {
  background-color: #e4e4e4;
  border-radius: 1px;
}

.progressbar:after {
  content: '';
  display: block;
  background-color: #7d12ff;
  width: 40%;
  height: 2px;
  border-radius: 1px;
}

.ql-toolbar.ql-snow {
  border: none !important;
}

.ql-container.ql-snow {
  border: none !important;
}

.rdw-editor-toolbar {
  padding: 0px !important;
  margin: 0px !important;
  border: none !important;
}

.rdw-option-wrapper {
  border: none !important;
}

.rdw-dropdown-wrapper {
  border: none !important;
}

.rdw-editor-main {
  padding: 10px;
}

.public-DraftStyleDefault-block {
  margin: 0.25rem 0 !important;
}

.rdtPicker td.rdtToday:before {
  content: '';
  display: inline-block;
  border-left: 7px solid transparent;
  border-bottom: 7px solid #7d12ff;
  border-top-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  bottom: 4px;
  right: 4px;
}
.rdtPicker td.rdtActive,
.rdtPicker td.rdtActive:hover {
  background-color: #7d12ff;
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  border-radius: 0.5rem;
}
.rdtPicker td.rdtActive.rdtToday:before {
  border-bottom-color: #fff;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: scroll;
}

.custom-swal2-html-container {
  margin: 0 !important;
}

.custom-swal2-popup {
  border-radius: 12px !important;
}

.swal2-container {
  z-index: 9999;
}

.custom-swal2-container {
  background: none !important;
}

.tiptap {
  :first-child {
    margin-top: 0;
  }

  /* Heading styles */
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
    margin-top: 1rem;
    text-wrap: pretty;
  }

  h1 {
    font-size: 1.5rem;
  }

  h2 {
    font-size: 1.4rem;
  }

  h3 {
    font-size: 1.3rem;
  }

  h4 {
    font-size: 1.2rem;
  }

  h5 {
    font-size: 1.1rem;
  }

  h6 {
    font-size: 1rem;
  }

  pre {
    background: #2D2B29;
    border-radius: 0.5rem;
    color: white;
    font-family: 'JetBrainsMono', monospace;
    margin: 1.5rem 0;
    padding: 0.75rem 1rem;

    code {
      background: none;
      color: inherit;
      font-size: 0.8rem;
      padding: 0;
    }

    /* Code styling */
    .hljs-comment,
    .hljs-quote {
      color: #616161;
    }

    .hljs-variable,
    .hljs-template-variable,
    .hljs-attribute,
    .hljs-tag,
    .hljs-regexp,
    .hljs-link,
    .hljs-name,
    .hljs-selector-id,
    .hljs-selector-class {
      color: #f98181;
    }

    .hljs-number,
    .hljs-meta,
    .hljs-built_in,
    .hljs-builtin-name,
    .hljs-literal,
    .hljs-type,
    .hljs-params {
      color: #fbbc88;
    }

    .hljs-string,
    .hljs-symbol,
    .hljs-bullet {
      color: #b9f18d;
    }

    .hljs-title,
    .hljs-section {
      color: #faf594;
    }

    .hljs-keyword,
    .hljs-selector-tag {
      color: #70cff8;
    }

    .hljs-emphasis {
      font-style: italic;
    }

    .hljs-strong {
      font-weight: 700;
    }
  }

  blockquote {
    border-left: 3px solid #9197a3;
    margin: 1.5rem 0;
    padding-left: 1rem;
  }

  /* List styles */
  ul,
  ol {
    padding: 0 1rem;
    margin: 1.25rem 1rem 1.25rem 0.4rem;
    list-style: revert;

    li p {
      margin-top: 0.25em;
      margin-bottom: 0.25em;
    }
  }

  hr {
    border: none;
    border-top: 1px solid #9197a3;
    cursor: pointer;
    margin: 2rem 0;
  }

  /* Link styles */
  a {
    color: #257de7;
    cursor: pointer;
  }

  /* Placeholder (at the top) */
  p.is-editor-empty:first-child::before {
    color: #9197a3;
    content: attr(data-placeholder);
    float: left;
    height: 0;
    pointer-events: none;
  }

  img {
    display: block;
    height: auto;
    margin: 1.5rem 0;
    max-width: 100%;
  }
}
